<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="PaymentSuccessModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="PaymentSuccessRef"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content PaymentSuccess">
          <div class="container p-5 " style="min-height: 30vh">
            <div style="text-align:end">
               <p
              @click="closeBtnModal"
              style="cursor:pointer;color:#4d4950;"
            >
              <i class="fa fa-times" style="color:#4d4950;" aria-hidden="true"></i>
            </p>
            </div>
            <div class="d-flex align-items-center justify-content-center  h-100">
              <div v-if="modalType && modalType == 'success'">
            <div class="" style="text-align:center;">
                <BIconCheckCircleFill style="color:#4d1b7e" font-scale="5" />
            </div>
            <p class="Title" style="text-align:center;">Payment was success</p>
           </div>
           <div  v-if="modalType && modalType == 'close'">
            <div class="" style="text-align:center;">
                <BIconQuestionCircleFill style="color:#4d1b7e" font-scale="5" />
            </div>
            <p class="Title" style="text-align:center;">Payment was incomplete</p>
           </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <!-- Actual Comp Start -->
  </div>
</template>

<script>
import { BIconCheckCircleFill,BIconQuestionCircleFill} from "bootstrap-vue"
import $ from "jquery";
export default {
 components:{BIconCheckCircleFill,BIconQuestionCircleFill},
  data() {
    return {
        modalType:null
    }
  },
  methods:{
    OpenModal(type) {
        this.modalType = type
      $("#PaymentSuccessModal").modal("show");
    },
    closeModal(){
        if(this.modalType == 'success'){
      
             this.modalType = null
            this.$router.push("/")
        } else {
             this.modalType = null
        }
    },
    closeBtnModal(){
       $('#PaymentSuccessModal').modal('hide')
      this.closeModal()
    }
  },
  mounted() {
    // $(this.$refs.PaymentSuccessRef).on("show.bs.modal", this.getTemplates);
     $(this.$refs.PaymentSuccessRef).on("hidden.bs.modal", this.closeModal);
  },
};
</script>

<style scoped>
.modal-content.PaymentSuccess{
  border-radius: 20px !important;
 
}
.Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 72px;
  color: #4d4950;
}
.btn-tab {
  background: transparent;
  border: 1px solid #cbcddc;
  color: #4d4950;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 4.5px;
}
.btn-tab.active {
  background: #4d1b7e;
  border: 1px solid #4d1b7e;
  color: #ffffff;
}
.w-90 {
  width: 90%;
}
.clear-page-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-border-bottom {
  border-bottom: 1px solid #cbcddc;
  height: 214px;
}
.see-demo-btn {
  background: transparent;
  border: 1px solid #b3afb6 !important;
  color: #6d6b6d !important;
  border-radius: 6px;
}
.see-demo-btn:hover {
  border: 1px solid #b3afb6 !important;
  background: #b3afb6 !important;
  color: #ffffff !important;
}
.choose-btn {
  background: #4d4950;
  border: 1px solid #4d4950;
  border-radius: 6px;
}
.choose-btn:hover {
  border: 1px solid #4d4950;
  background: #4d4950cc;
  color: #ffffff !important;
}

.chooseTemplateInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}
/* .chooseTemplateInput input{
  background:transparent;
  border:none;
  outline:none;
} */
.QuizNameInput:focus {
  border: none !important;
  outline: none !important;
}
.selected-template {
  border: 4px solid #4d1b7e;
  border-radius: 12px;
}
.CreateBtn:disabled {
  opacity: 60%;
}
</style>
